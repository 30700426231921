import React from "react"
import Head from "../components/head"
import Layout from "../layouts/layout"
import Posts from "../components/blogList/Posts"
import { SectionHeader } from "../components/Common"

function BlogPage() {
  return (
    <Layout>
      <Head title="Blog" />
      <SectionHeader>All Posts</SectionHeader>
      <Posts limit={undefined} />
    </Layout>
  )
}

export default BlogPage
